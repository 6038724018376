<template>
  <div>
    <el-card v-if="!mobile">
      <el-form inline ref="form">
        <el-form-item label="手机号：">
          <el-input v-model="searchData.mobile" placeholder="请输入手机号查找"></el-input>
        </el-form-item>
        <el-form-item label="审批状态">
          <el-select v-model="searchData.approvalStatus" placeholder="请选择">
            <el-option value label="全部"></el-option>
            <el-option value="0" label="待审批"></el-option>
            <el-option value="1" label="审批通过"></el-option>
            <el-option value="2" label="审批驳回"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="$refs.table.reloadData()">搜索</el-button>
        </el-form-item>
      </el-form>
    </el-card>

    <cui-table :url="$api.carPlateBindingAuditList" :data="searchData" ref="table" width="200">
      <el-table-column label="序号" type="index" min-width="60"></el-table-column>
      <el-table-column label="申请人" prop="userName" width="150"></el-table-column>
      <el-table-column label="绑定车牌" width="180">
        <template slot-scope="scope">
          【{{scope.row.plateColor|color}}】 {{scope.row.plate}}
        </template>
      </el-table-column>
      <el-table-column prop="drivingLicenseImg" label="申诉资料照片" width="120">
        <template slot-scope="scope">
          <el-image style="width: 50px; height: 50px" :src="scope.row.drivingLicenseUrl" :preview-src-list="[scope.row.drivingLicenseUrl]"></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" label="申诉时间" min-width="150"></el-table-column>
      <el-table-column label="审批备注" min-width="170">
        <template slot-scope="scope">
          {{scope.row.remark?scope.row.remark:'--'}}
        </template>
      </el-table-column>
      <el-table-column prop="updateTime" label="审批时间" min-width="150"></el-table-column>
      <el-table-column prop="approvalStatus" label="审批状态" width="110">
        <template slot-scope="scope">
          <el-tag size="medium" v-if="scope.row.approvalStatus==0" type="primary">待审批</el-tag>
          <el-tag size="medium" v-if="scope.row.approvalStatus==1" type="success">审批通过</el-tag>
          <el-tag size="medium" v-if="scope.row.approvalStatus==2" type="danger">已驳回</el-tag>
        </template>
      </el-table-column>
      <el-table-column fixed="right" label="操作" width="110">
        <template slot-scope="scope">
          <el-button :disabled="scope.row.approvalStatus!=0" type="text" @click="handle(scope.row.id,'1')">通过</el-button>
          <el-button :disabled="scope.row.approvalStatus!=0" type="text" @click="handle(scope.row.id,'2')">驳回</el-button>
        </template>
      </el-table-column>
    </cui-table>
  </div>
</template>

<script>
export default {
  name: "matterEdit",
  title: "车辆绑定审核",
  data() {
    return {
      searchData: {
        currentPage: 1,
        pageSize: 10,
        count: 0,
        mobile: "",
        approvalStatus: "",
      },
    };
  },
  watch: {
    mobile: function (value = 6) {
      this.searchData.mobile = value;
      this.$refs.table.reloadData();
    },
  },
  filters: {
    color: function (val) {
      const map = {
        1: "蓝牌",
        2: "黄牌",
        3: "白牌",
        4: "黑牌",
        5: "绿牌",
        6: "未知颜色",
      };
      return map[val];
    },
  },
  created() {
    if (this.$route.query.status) {
      this.searchData.approvalStatus = this.$route.query.status;
    }
    this.mobile ? (this.searchData.mobile = this.mobile) : "";
  },
  methods: {
    handle(id, status) {
      this.$prompt("请输入审核备注", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          this.$http.post(
            this.$api.carPlateBindingAudit,
            {
              approvalStatus: status,
              id: id,
              remark: value,
            },
            (res) => {
              this.$message.success("申诉处理成功！");
              this.$refs.table.reloadData();
            }
          );
        })
        .catch(() => {});
    },
  },
};
</script>

<style>
</style>