import cuiTable from './table.vue'
import cuiDialog from './dialog.vue'
import cuiupload from './upload.vue'
// import cuiEditoe from './editor.vue'
import cuiHeader from './header.vue'
import cuiLocation from './location.vue'
import cuiMap from './map.vue'
import cuiPlate from './plate.vue'


export default {
    install: function (Vue) {
        Vue.component('cui-table', cuiTable)
        Vue.component('cui-dialog', cuiDialog)
        Vue.component('cui-upload', cuiupload)
        // Vue.component('cui-editor', cuiEditoe)
        Vue.component('cui-header', cuiHeader)
        Vue.component('cui-location', cuiLocation)
        Vue.component('cui-map', cuiMap)
        Vue.component('cui-plate', cuiPlate)
    }
}