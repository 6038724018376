<template>
  <div>
    <el-card v-if="!mobile">
      <el-form inline ref="form">
        <el-form-item label="订单号">
          <el-input v-model="searchData.orderId" placeholder="请输入订单号查找"></el-input>
        </el-form-item>
        <el-form-item label="姓名">
          <el-input v-model="searchData.userName" placeholder="请输入用户姓名查找"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="searchData.mobile" placeholder="请输入手机号查找"></el-input>
        </el-form-item>
        <el-form-item label="车牌号">
          <el-input v-model="searchData.plate" placeholder="请输入车牌号查找"></el-input>
        </el-form-item>
        <el-form-item label="停车场">
          <el-select v-model="searchData.parkId" clearable filterable remote reserve-keyword placeholder="请输入关键词查询停车场" :remote-method="getParkAreaList">
            <el-option v-for="item in parkingAreaList" :key="item.id" :label="item.parkingName" :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="车位号">
          <el-input v-model="searchData.seatNum" placeholder="请输入车位号"></el-input>
        </el-form-item>
        <!-- <el-form-item label="类型">
          <el-select v-model="searchData.orderType">
            <el-option label="异常订单" :value="40"></el-option>
            <el-option label="全部订单" value=""></el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item label="取证超时">
          <el-select v-model="searchData.unEvidenceTime">
            <el-option label="不筛选" value=""></el-option>
            <el-option label="15分钟以上未取证" value="15"></el-option>
            <el-option label="30分钟以上未取证" value="30"></el-option>
            <el-option label="45分钟以上未取证" value="45"></el-option>
            <el-option label="60分钟以上未取证" value="60"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="订单离场类型">
          <el-select v-model="searchData.orderLeaveType">
            <el-option label="场内订单" value="1"></el-option>
            <el-option label="离场订单" value="2"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="$refs.table.reloadData()">搜索</el-button>
        </el-form-item>
      </el-form>
    </el-card>
    <cui-table :url="$api.selectOrderList" :data="searchData" ref="table" width="200" :handleData="tableHandleData">
      <el-table-column fixed prop="id" label="订单ID" width="150"></el-table-column>
      <el-table-column label="订单类型" width="100">
        <template slot-scope="scope">
          <el-tag size="medium" v-if="Number(scope.row.arrearsAmount)>0" type="danger">欠费订单</el-tag>
          <el-tag size="medium" v-else type="success">正常订单</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="订单用户姓名" width="170">
        <template slot-scope="scope">{{scope.row.userName?scope.row.userName+`【${scope.row.userMobile}】`:"临时用户"}}</template>
      </el-table-column>
      <el-table-column prop="plate" label="车牌号" width="120">
        <template slot-scope="scope">
          <el-tag size="medium" v-if="!scope.row.plate" type="danger">无牌车</el-tag>
          <el-tag size="medium" v-else type="success">{{scope.row.plate}}</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="parkingName" label="停车场" width="180"></el-table-column>
      <el-table-column prop="seatNum" label="车位编号" width="150"></el-table-column>
      <el-table-column label="入场时间" min-width="250">
        <template slot-scope="scope">
          <span>{{scope.row.enterTime}}&nbsp;&nbsp;</span>
          <el-image v-if="scope.row.inPic.length>0" style="width: 14px; height: 14px" :src="scope.row.inPic[0]" :preview-src-list="scope.row.inPic"></el-image>
        </template>
      </el-table-column>
      <el-table-column label="出场时间" min-width="250">
        <template slot-scope="scope">
          <span>{{scope.row.leaveTime}}&nbsp;&nbsp;</span>
          <el-image v-if="scope.row.outPic" style="width: 14px; height: 14px" :src="scope.row.outPic" :preview-src-list="[scope.row.outPic]"></el-image>
        </template>
      </el-table-column>
      <el-table-column prop="payAmount" label="支付金额" width="100">
        <template slot-scope="scope">{{scope.row.payAmount}}元</template>
      </el-table-column>
      <!-- <el-table-column prop="payAmount" label="欠费金额" width="100">
        <template slot-scope="scope">{{Number(scope.row.arrearsAmount)>0?scope.row.arrearsAmount:"无欠费"}}</template>
      </el-table-column> -->
      <el-table-column prop="payTime" label="支付时间" width="150"></el-table-column>
      <el-table-column prop="status" label="标记状态" width="100">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status==0" type="success">正常订单</el-tag>
          <el-tag v-else-if="scope.row.status=='1'">0元放行</el-tag>
          <el-tag v-else-if="scope.row.status=='2'">退款</el-tag>
          <el-tag v-else-if="scope.row.status=='3'">补偿优惠券</el-tag>
          <el-tag v-else-if="scope.row.status=='4'">修改进出场</el-tag>
          <el-tag v-else-if="scope.row.status=='100'" type="warning">异常待处理</el-tag>
          <el-tag v-else type="danger">状态异常</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" width="170">
        <template slot-scope="scope">
          <el-button v-if="scope.row.orderType=='1'" type="text" @click="saveOrderOutLog(scope.row.id)">手动离场</el-button>
          <el-button type="text" :disabled="['1','2','3','4','100'].indexOf(scope.row.status)>-1" @click="createErrorOrder(scope.row)">
            生成异常记录</el-button>
          <!-- <el-button type="text" @click="selectUrgeDetail(scope.row.userId)">催收记录</el-button> -->
        </template>
      </el-table-column>
    </cui-table>
  </div>
</template>

<script>
export default {
  name: "orderList",
  title: "平台订单",
  props: ["mobile"],
  data() {
    return {
      searchData: {
        orderId: "",
        userName: "",
        mobile: "",
        plate: "",
        parkId: "",
        seatNum: "",
        orderType: "",
        unEvidenceTime: "",
        orderLeaveType: "1",
        currentPage: 1,
        pageSize: 10,
        count: 0,
      },
      parkingAreaList: [],
      tableHandleData: function (data) {
        data.map((e) => {
          if (e.imageArrays) {
            let obj = JSON.parse(e.imageArrays),
              arr = [];
            for (const key in obj) {
              if (obj.hasOwnProperty.call(obj, key)) {
                arr.push(obj[key]);
              }
            }
            e.inPic = arr;
          } else if (e.inPic) {
            e.inPic = [e.inPic];
          } else {
            e.inPic = [];
          }
        });
        return data;
      },
    };
  },
  watch: {
    mobile: function (value) {
      this.searchData.mobile = value;
      this.$refs.table.reloadData();
    },
  },
  created() {
    this.mobile ? (this.searchData.mobile = this.mobile) : "";
  },
  methods: {
    createErrorOrder(item) {
      this.$root.$emit("showWorkorderDialog", {
        type: "order",
        data: item,
        callback: () => {
          this.$refs.table.reloadData();
        },
      });
    },
    getParkAreaList(query) {
      if (query !== "") {
        this.$http.post(
          this.$api.getParkAreaList,
          {
            currentPage: 1,
            pageSize: 10,
            parkingName: query,
          },
          (res) => {
            this.parkingAreaList = res.list;
          }
        );
      } else {
        this.parkingAreaList = [];
      }
    },
    saveOrderOutLog(id) {
      this.$prompt("请输入手动离场备注", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      })
        .then(({ value }) => {
          this.$http.post(
            this.$api.saveOrderOutLog,
            { id, remark: value },
            (res) => {
              this.$message.success("车辆手动离场成功！");
              this.$refs.table.reloadData();
            }
          );
        })
        .catch(() => {});
    },
  },
};
</script>